import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
// import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  render() {
    const { game } = this.props;
    const players = game.data.players || [];

    const leaderboard = players.map((p, pi) => {
      const correct = p.estimates.reduce((acc, est) => {
        const accu = ((100 / est.item.price) * est.price).toFixed(2);
        return accu >= 90 && accu <= 110 ? acc + 1 : acc;
      }, 0);
      return {
        name: p.name,
        lastname: p.lastname,
        email: p.email,
        correct,
      };
    });

    leaderboard.sort(function (a, b) {
      return b.correct - a.correct;
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Name',
              'Surname',
              'Email',
              <span style={{ whiteSpace: 'nowrap' }}>Correct</span>,
            ]}
            fields={['name', 'lastname', 'email', 'correct']}
            sortables={['name', 'lastname', 'email', 'correct']}
            data={leaderboard}
            onRowClick={this.rowClick}
          />
        </div>

        <Modal
          isOpen={this.state.group ? true : false}
          centered={true}
          size='xxl'
          toggle={() => this.setState({ group: null })}>
          <ModalBody className='text-center'>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size='2x'
              className='icon bg-white text-danger'
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={() => this.setState({ group: null })}
            />
            {this.state.group && <GroupDebrief group={this.state.group} />}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
