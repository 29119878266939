import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Constants
// import { issues } from '../../constants/issues'

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class Aggregate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 1,
      real: false,
    };

    // Bindings
    // this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const { game } = this.props;

    const estimates = game.data.estimates || [];
    const items = game.data.itemset?.items || [];
    const realPrices = [];

    // Price buckets
    const priceBuckets = [...Array(21).keys()].map((p) =>
      p < 20 ? `${p} - ${p + 0.99}` : `> ${p}`
    );

    const buckets = priceBuckets.map((p, pi) => {
      const min = pi;
      const max = pi + 0.99;
      // Get the number of procuts with real prices in this bucket
      const realPricesInThisBucket = this.state.real
        ? items.filter((i) => min <= Number(i.price) && Number(i.price) <= max)
        : [];
      realPrices[pi] = {
        x: pi,
        y: realPricesInThisBucket.length,
        name: realPricesInThisBucket
          .map(
            (pn) => `<b>${pn.name}</b>: ${game.data.symbol || '$'}${pn.price}`
          )
          .join('<br/>'),
      };
      // Return the total number of estimates in this bucket
      return estimates.filter((est) => min <= est.price && est.price <= max)
        .length;
    });

    // console.log({ realPrices });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Aggregate
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-12'>
            <div className='mt-3'>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'column',
                    height: 450,
                    backgroundColor: 'transparent',
                    plotBackgroundColor: null,
                  },

                  credits: {
                    enabled: false,
                  },

                  title: { text: '' },

                  tooltip: {
                    formatter: function () {
                      if (this.series.name == 'Prices') {
                        //return this.point.name + '<br/><b>$ ' + this.x + '</b>';
                        return this.point.name;
                      } else {
                        return (
                          'Range: ' +
                          this.x +
                          '<br/><b>Estimates: ' +
                          this.y +
                          '</b>'
                        );
                      }
                    },
                  },
                  plotOptions: {
                    column: {
                      grouping: false,
                    },
                  },
                  xAxis: [
                    {
                      title: {
                        text: `Price Range (${game.data.symbol || '$'})`,
                      },
                      gridLineWidth: 1,
                      categories: priceBuckets,
                      plotBands: [
                        {
                          color: 'rgba(0, 0, 0, 0.05)',
                          from: 2.5,
                          to: 5.5,
                          zIndex: 1,
                          label: {
                            text: 'Typical Estimate',
                            y: 30,
                            align: 'center',
                            style: {
                              color: '#606060',
                            },
                          },
                        },
                      ],
                    },
                    {
                      opposite: true,
                      allowDecimals: false,
                      min: 0,
                      // max: 21,
                      minRange: 20,
                      tickInterval: 1,
                      alignTicks: false,
                      labels: { enabled: false },
                    },
                  ],
                  yAxis: [
                    {
                      allowDecimals: false,
                      title: {
                        text: 'Estimate Count',
                      },
                      tickInterval: 1,
                      alignTicks: true,
                    },
                    {
                      opposite: true,
                      title: {
                        text: 'Count: Actual Price',
                      },
                      allowDecimals: false,
                      min: 0,
                      max: 5,
                      tickInterval: 1,
                      alignTicks: true,
                    },
                  ],
                  series: [
                    {
                      name: 'Estimate Count',
                      type: 'column',
                      showInLegend: false,
                      color: 'rgba(124,181,236,0.8)',
                      data: buckets,
                      pointPadding: 0,
                      groupPadding: 0,
                      zIndex: 1,
                    },
                    {
                      showInLegend: false,
                      name: 'Prices',
                      color: 'rgba(83, 223, 83, .5)',
                      data: realPrices,
                      pointPadding: 0.2,
                      groupPadding: 0,
                      zIndex: 1,
                      visible: true,
                      yAxis: 1,
                    },
                  ],
                }}
              />
              <div className='text-center'>
                <button
                  className={
                    this.state.real
                      ? 'btn btn-primary'
                      : 'btn btn-outline-primary'
                  }
                  onClick={() => this.setState({ real: !this.state.real })}>
                  {this.state.real
                    ? 'Hide Actual Prices'
                    : 'Show Actual Prices'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Aggregate);
