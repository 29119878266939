import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import posed, { PoseGroup } from 'react-pose';

// Components
import RoundSelect from './RoundSelect';

import logo from '../assets/icon.png'; // relative path to image

// import logoKWhite from '../assets/k_logo_100_white.png' // "K" logo, solid white
import logoWide from '../assets/simcase_wide.png'; // relative path to image
import logoKellogg from '../assets/Kellogg_H_RGB.png'; // relative path to image
// import iconDocument from '../assets/icon_document.png' // relative path to image

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faRedoAlt,
  faFileAlt,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

// Add FontAwesome icons
library.add([
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faRedoAlt,
  faFileAlt,
  faQuestionCircle,
]);

const Menu = posed.div({
  init: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    delayChildren: 250,
    staggerChildren: 100,
  },
  exit: {
    opacity: 0,
  },
});

const MenuClose = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 250 },
  },
  exit: {
    opacity: 0,
    y: -30,
    transition: { duration: 250 },
  },
});

const MenuItem = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 250 },
  },
  exit: {
    opacity: 0,
    y: 30,
    transition: { duration: 250 },
  },
});

// The Header creates links that can be used to navigate
// between routes.
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      about: false,
    };

    // Bindings
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleAbout = this.toggleAbout.bind(this);
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  toggleAbout() {
    this.setState({ about: !this.state.about, open: false });
  }

  render() {
    const { user, game, subtitle = true } = this.props;
    const title = game.data ? game.data.name : '';
    const fromHBP = user && user.from && user.from === 'lti-hbp' ? true : false;
    const isInstructor =
      user && user.role && user.role === 'instructor' ? true : false;

    // Logo content
    const logoImg = (
      <img
        src={logo}
        className='logo'
        style={{
          height: '35px',
          marginRight: '15px',
          // marginTop: '-5px',
        }}
        alt='logo'
      />
    );

    const logoSubtitle = subtitle && game && (
      <span
        className='d-inline-block font-weight-light text-primary ml-3'
        style={{
          // lineHeight: '20px',
          maxWidth: '30vw',
          // overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {title}
      </span>
    );

    const scProductUrl = process.env.REACT_APP_LIBRARY_URL + '?new=true';
    let materialsLink = process.env.REACT_APP_LIBRARY_URL + '?materials=true';
    let helpLink = 'https://simcase.help';

    // If user comes from HBPE
    if (fromHBP) {
      materialsLink = process.env.REACT_APP_HBP_PRODUCT_URL || '#';
      helpLink = 'https://help.hbsp.harvard.edu/hc/en-us';
    }

    return (
      <header className='app-header' style={{ margin: '0 -15px' }}>
        <nav id='main-menu' className='navbar'>
          <div className='flex-grow-1'>
            {user?.role === 'instructor' && fromHBP && (
              <span className='navbar-brand d-flex align-items-center title fs-4'>
                {logoImg}
                Checkout {logoSubtitle}
              </span>
            )}

            {(user?.role !== 'instructor' || !fromHBP) && (
              <Link
                className='navbar-brand d-flex align-items-center title fs-4'
                to='/games'>
                {logoImg}
                Checkout {logoSubtitle}
              </Link>
            )}

            {game?.sample !== true && subtitle && game?.status === 'launched' && (
              <div className='d-inline-block' style={{ padding: '15px 0' }}>
                <RoundSelect />
              </div>
            )}
          </div>

          {/* Note Links */}
          <a
            href={materialsLink}
            className='btn btn-link text-muted ml-auto'
            target='_blank'>
            <FontAwesomeIcon className='mr-1' icon='file-alt' />{' '}
            <small>Resources</small>
          </a>

          <a
            href={helpLink}
            className='btn btn-link text-muted ml-5'
            target='_blank'>
            <FontAwesomeIcon className='mr-1' icon='question-circle' />{' '}
            <small>Help</small>
          </a>

          {game.data &&
            ['launched', 'finalized'].includes(game.data.status) && [
              <NavLink
                key='gd-players'
                to={`/games/${game.data._id}/players`}
                className='btn btn-outline-primary ml-5'
                activeClassName='btn-primary text-white'>
                Players
              </NavLink>,
              <NavLink
                key='gd-debrief'
                to={`/games/${game.data._id}/debrief/overview`}
                isActive={(match, location) =>
                  location.pathname.includes('debrief')
                }
                className='btn btn-outline-primary ml-5'
                activeClassName='btn-primary text-white'>
                Debrief
              </NavLink>,
            ]}

          {!game.data && !fromHBP && (
            <a
              href={isInstructor ? scProductUrl : '/admin/games/new'}
              className='btn btn-outline-primary ml-5'>
              Create Game
            </a>
          )}

          <div
            className='menu-toggler float-right text-primary mr-3 ml-5'
            onClick={this.toggleMenu}>
            <FontAwesomeIcon
              icon='bars'
              size='2x'
              style={{ cursor: 'pointer' }}
            />
          </div>
        </nav>

        <PoseGroup>
          {this.state.open && (
            <Menu key='menu'>
              <div className='side-menu-wrap'>
                <div className='side-menu'>
                  <MenuClose className='menu-close' onClick={this.toggleMenu}>
                    <FontAwesomeIcon icon='times-circle' />
                  </MenuClose>

                  <MenuItem className='menu-item' onClick={this.toggleAbout}>
                    <span>
                      <FontAwesomeIcon
                        icon='route'
                        size='3x'
                        className='icon'
                      />
                      <h5 className='mb-0'>About</h5>
                      <small className='mt-0 mb-0'>About this SimCase</small>
                    </span>
                  </MenuItem>

                  {(user?.role !== 'instructor' || !fromHBP) && (
                    <MenuItem
                      className='menu-item mt-3'
                      onClick={this.toggleMenu}>
                      <NavLink to='/games' activeClassName=''>
                        <FontAwesomeIcon
                          icon='list-alt'
                          size='3x'
                          className='icon'
                        />
                        <h5 className='mb-0'>Game Sessions</h5>
                        <small className='mt-0 mb-0'>List of games</small>
                      </NavLink>
                    </MenuItem>
                  )}

                  {!fromHBP && (
                    <MenuItem
                      className='menu-item mt-3'
                      onClick={this.toggleMenu}>
                      <NavLink to='/logout' activeClassName=''>
                        <FontAwesomeIcon
                          icon='power-off'
                          size='3x'
                          className='icon'
                        />
                        <h5 className='mb-0'>Log Out</h5>
                        <small className='mt-0 mb-0'>Close your session</small>
                      </NavLink>
                    </MenuItem>
                  )}
                </div>
                <div
                  onClick={this.toggleMenu}
                  className='side-menu-overlay'></div>
              </div>
            </Menu>
          )}
        </PoseGroup>

        {/* About Modal */}
        <Modal
          isOpen={this.state.about}
          toggle={this.toggleAbout}
          centered={true}>
          <ModalBody className='text-center'>
            <div className='mt-4 mb-4'>
              <div style={{ padding: '0 10%' }}>
                <h4 className='mt-0'>About Checkout</h4>
                <p className='mt-5'>
                  Written by
                  <br />
                  Eric T. Anderson Hartmarx
                  <br />
                  Professor of Marketing Director of the Center for Global
                  Marketing Practice
                </p>
                <img
                  src={logoKellogg}
                  className='img-fluid mt-2'
                  alt='Kellogg'
                />
                <p className='mt-5'>Produced by SimCase</p>
                <img
                  src={logoWide}
                  className='img-fluid'
                  style={{ maxHeight: '50px' }}
                  alt='SimCase'
                />
                <p className='mt-5 mb-0'>
                  <span
                    className='btn btn-outline-primary'
                    onClick={this.toggleAbout}>
                    Close
                  </span>
                </p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, game } = state;
  const { user } = authentication;
  return {
    game,
    user,
  };
}

export default connect(mapStateToProps)(Header);
