import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions, modalActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class Debrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
    this.openFeedback = this.openFeedback.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  openFeedback() {
    const { game } = this.props;
    const { dispatch } = this.props;
    const modalBody = (
      <p className='mt-3 text-center'>
        Are you sure you want to open feedback to learners?
      </p>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Cancel
      </button>,
      <button
        key='modal-btn-delete'
        className='btn btn-success'
        onClick={() => {
          dispatch(
            gameActions.setStatus({ _id: game.data._id, status: 'finalized' })
          );
          dispatch(modalActions.close());
        }}>
        Open
      </button>,
    ];
    // Open the modal
    dispatch(
      modalActions.open({
        title: 'Confirmation',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  }

  render() {
    const { game } = this.props;

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Overview
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-6 offset-3 text-center'>
            <p className='lead mt-5 pt-5'>
              Once learners have submitted their estimates, begin your debrief
              by clicking through the charts above: Items, Aggregate,
              Leaderboard.
            </p>
            <p className='lead'>
              When you are prepared to load learner feedback onto their devices,
              click on the <b>Open Feedback</b> button below. Note that no
              additional student estimates will be permitted since all learners
              will be able to see the actual prices.
            </p>
            <p className='mt-5'>
              {game.data.status === 'launched' ? (
                <button
                  className='btn btn-outline-primary btn-lg'
                  onClick={this.openFeedback}>
                  Open Feedback
                </button>
              ) : (
                <span className='text-success'>
                  Feedback is open to learners
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Debrief);
