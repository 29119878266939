import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';

// Constants
// import { issues } from '../../constants/issues'

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pid: 0,
      reveal: [],
    };

    // Bindings
    this.setProduct = this.setProduct.bind(this);
    this.revealProduct = this.revealProduct.bind(this);
  }

  setProduct(pid) {
    this.setState({ pid });
  }

  revealProduct(pid) {
    const { reveal } = this.state;
    const newReveal = [...reveal];
    newReveal[pid] = true;
    this.setState({ reveal: newReveal });
  }

  render() {
    const { pid, reveal } = this.state;
    const { game } = this.props;
    const players = game.data?.players || [];
    const items = game.data.itemset?.items || [];
    const estimates = game.data.estimates || [];

    // Price buckets
    const priceBuckets = [...Array(21).keys()].map((p) =>
      p < 20 ? `${p} - ${p + 0.99}` : `> ${p}`
    );

    const buttons = items.map((i, ii) => (
      <button
        key={`pid-${ii}`}
        className={`btn btn-sm mr-2 ${
          pid === ii ? 'btn-primary' : 'btn-outline-primary'
        }`}
        onClick={() => this.setProduct(ii)}>
        {ii + 1}
      </button>
    ));

    const product = items[pid] || null;
    const priceBand =
      product && reveal[pid]
        ? {
            color: 'rgba(0, 0, 0, 0.05)',
            from: product.price * 0.9,
            to: product.price * 1.1,
            zIndex: 1,
            label: {
              text: 'Actual Price<br/>+/- 10%',
              y: 30,
              align: 'center',
              style: {
                color: '#606060',
              },
            },
          }
        : {};

    // // Product image from remote url or local
    // if (gameData.items[idx].image.startsWith('http')) {
    //   itemImg.src = gameData.items[idx].image;
    // } else {
    //   itemImg.src =
    //     o.site_url + '/game_assets/default/item' + (idx + 1) + '.png';
    // }

    const currentProduct = product ? (
      <div className=''>
        <div className='text-center'>
          <img
            src={
              product.image.startsWith('http')
                ? product.image
                : require(`../../assets/default/item_${pid + 1}.png`)
            }
            alt={product.name}
            className='img-fluid mb-4'
            style={{ width: '200px' }}
          />
          <br />
          <b>{product.name}</b>
          <p>{product.details}</p>
          {reveal[pid] ? (
            <h4>
              {game.data.symbol || '$'}
              {product.price}
            </h4>
          ) : (
            <button
              className='btn btn-primary mb-2'
              onClick={() => this.revealProduct(pid)}>
              Reveal price
            </button>
          )}
          <p className='mb-0 text-muted'>
            <small>{product.source}</small>
          </p>
        </div>
      </div>
    ) : null;

    const productEstimates = product
      ? estimates.filter((e) => e.item._id === product._id)
      : [];

    const buckets = priceBuckets.map((p, pi) => {
      const min = pi;
      const max = pi === priceBuckets.length - 1 ? Infinity : pi + 0.99;
      const count = productEstimates.filter(
        (est) => min <= est.price && est.price <= max
      );
      // console.log({ count });
      return {
        x: pi,
        y: count.length,
        name: count
          .map((pn) => {
            const pl = players.find((p) => pn.player === p._id);
            const pname =
              pl && `${pl.name} ${pl.lastname}`.trim()
                ? `${pl.name} ${pl.lastname}`
                : pl.email;
            return `${pname}: ${game.data.symbol || '$'}${numeral(
              pn.price
            ).format('0.00')}`;
          })
          .join('<br/>'),
      };
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Items
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-4'>
            <div className='card border-none shadow mt-3'>
              <div className='card-body'>
                <div className='text-center'>{buttons}</div>
                <div className='mt-5'>{currentProduct}</div>
              </div>
            </div>
          </div>
          <div className='col-8'>
            <div className='mt-3'>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'column',
                    height: 500,
                    backgroundColor: 'transparent',
                    plotBackgroundColor: null,
                  },

                  credits: {
                    enabled: false,
                  },

                  title: { text: '' },

                  tooltip: {
                    formatter: function () {
                      if (this.series.name === 'Estimate Count') {
                        //return this.point.name + '<br/><b>$ ' + this.x + '</b>';
                        return (
                          'Range: ' +
                          this.x +
                          '<br/>' +
                          '<b>Estimates: ' +
                          this.y +
                          '</b>' +
                          '<br/>' +
                          this.point.name
                        );
                      } else {
                        return (
                          'Range: ' +
                          this.x +
                          '<br/><b>Estimates: ' +
                          this.y +
                          '</b>'
                        );
                      }
                    },
                  },

                  xAxis: [
                    {
                      title: {
                        text: `Price Range (${game.data.symbol || '$'})`,
                      },
                      gridLineWidth: 1,
                      categories: priceBuckets,
                      plotBands: [priceBand],
                    },
                  ],
                  yAxis: [
                    {
                      title: {
                        text: 'Estimate Count',
                      },
                      allowDecimals: false,
                      min: 0,
                      // max: 20,
                      minRange: 19,
                      tickInterval: 1,
                    },
                  ],
                  series: [
                    {
                      showInLegend: false,
                      name: 'Estimate Count',
                      type: 'column',
                      data: buckets,
                      pointPadding: 0,
                      groupPadding: 0,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Products);
